import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import api from 'api';
import { AssistantType } from 'api/resources/accounts/types';
import { useAppSelector } from './reduxHooks';
import { useIsFeatureOn } from './useFeatureSwitch';
import useSampleProject from './useSampleProjects/useSampleProject';

export const generateProjectsQueryKey = (accountId: string) => [
  accountId,
  'projects',
];

const useProjects = () => {
  const accountId = useAppSelector((state) => state.account.id);
  const { inSampleProject } = useSampleProject();
  const { isFeatureOn } = useIsFeatureOn();
  const isPortalProjectsFeatureOn = isFeatureOn('portal_projects_in_jupiter');

  const {
    data,
    isLoading: isLoadingProjects,
    isError: isProjectsError,
  } = useQuery({
    queryKey: generateProjectsQueryKey(accountId),
    queryFn: () => api.getProjects(accountId),
    enabled: !inSampleProject && !!accountId,
  });

  const projects = useMemo(() => {
    const allProjects = data?.projects ?? [];
    if (isPortalProjectsFeatureOn) {
      return allProjects;
    }
    return allProjects.filter(
      (project) => project.type !== AssistantType.INTENT,
    );
  }, [data?.projects, isPortalProjectsFeatureOn]);

  const projectIdToNameMap = useMemo(() => {
    const idToNameMap = {} as { [key: string]: string };

    projects.forEach(
      (project) => (idToNameMap[project.id] = project.name ?? ''),
    );

    return idToNameMap;
  }, [projects]);

  return {
    projects,
    isLoadingProjects,
    isProjectsError,
    projectIdToNameMap,
  };
};

export default useProjects;
