import { ClientEnvironment } from '@polyai/common/constants/global.constants';

export const NUM_DEPLOYMENTS_INCREMENT = 20;
export const NUM_OF_VERSION_CHARS_TO_SHOW = 9;

export const DEPLOYMENT_TIME_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false,
  timeZone: 'GMT',
};

// Redefining client environments to guarantee the order of the environments
export const CLIENT_ENVIRONMENTS = [
  ClientEnvironment.SANDBOX,
  ClientEnvironment.PRE_RELEASE,
  ClientEnvironment.LIVE,
];
