import { useEffect } from 'react';

import useRequiredParams from '@polyai/common/hooks/useRequiredParams';
import { useRouter } from '@polyai/common/hooks/useRouter';
import { Loader } from '@polyai/ui/components/atoms/Loader';

import useProjectInit from 'hooks/useProjectInit';
import { useProjectResources } from 'hooks/useProjectResources';
import { generateProjectLandingResource } from 'lib/routeHelper';
import ErrorPage, { ErrorStatus } from 'screens/ErrorPage';

const ProjectRedirect = () => {
  const { isLoading, isError } = useProjectInit();
  const { isFetchingProjectFeatures } = useProjectResources();
  const { replaceUrl } = useRouter();

  const { accountId, projectId } = useRequiredParams<{
    accountId: string;
    projectId: string;
  }>();

  useEffect(() => {
    const redirectUser = async () => {
      const path = await generateProjectLandingResource(accountId, projectId);
      replaceUrl(path);
    };
    if (!isFetchingProjectFeatures && !isLoading && !isError) {
      void redirectUser();
    }
  }, [
    accountId,
    projectId,
    isFetchingProjectFeatures,
    isLoading,
    isError,
    replaceUrl,
  ]);

  if (isFetchingProjectFeatures || isLoading) {
    return <Loader inProjectPage={false} fullScreen spinner />;
  }

  if (isError) {
    return <ErrorPage type={ErrorStatus.NOT_FOUND} />;
  }

  return <Loader fullScreen spinner />;
};

export default ProjectRedirect;
