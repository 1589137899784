import { motion } from "framer-motion";
import styled, { css } from "styled-components";

import themeColors from "styles/config/colors";
import sizes from "styles/config/sizes";

export const Sidebar = styled(motion.div)<{
  $paddingTop?: number;
  $position: "left" | "right";
  $isMainNavOpen?: boolean;
}>`
  position: fixed;
  top: 0;

  flex-shrink: 0;

  width: fill-available;
  height: 100dvh;
  padding-top: ${({ $paddingTop }) => $paddingTop}px;

  background-color: ${themeColors.background};
  box-shadow: ${({ $position }) => ($position === "right" ? "-" : "")}2px 0 10px
    0 ${themeColors.shadow};

  ${({ $isMainNavOpen }) =>
    $isMainNavOpen
      ? css`
          max-width: calc(100% - ${sizes.mainNavWidth}px);
        `
      : css`
          max-width: 100%;
        `};
  transition: max-width 0.2s ease-in-out, padding 0.2s ease-in-out;

  ${({ $position }) => ($position === "left" ? "left: 0;" : "right: 0;")}
`;

export const Overlay = styled.div<{ $open: boolean }>`
  pointer-events: none;

  position: fixed;
  z-index: 30;
  inset: 0 0 0 100%;

  background-color: transparent;

  transition: background-color 0.2s ease-in-out;

  ${({ $open }) =>
    $open &&
    css`
      pointer-events: all;
      left: 0;
      background-color: ${themeColors.backgroundOverlay};
    `}
`;

export const FakeSidebar = styled(motion.div)`
  flex-shrink: 0;
`;

export const SidebarStickyHeader = styled.div`
  position: sticky;
  z-index: 15;
  top: 0;

  background: ${themeColors.background};
  border-bottom: 1px solid ${themeColors.borderSubtle};
`;

export const SidebarScrollableContent = styled.div<{
  $hideScrollbar?: boolean;
}>`
  position: relative;
  overflow-y: scroll;
  overscroll-behavior: none;
  height: 100%;

  ${({ $hideScrollbar }) =>
    $hideScrollbar &&
    css`
      scrollbar-width: none;

      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    `}
`;

export const Fader = styled(motion.div)<{ position: "top" | "bottom" }>`
  ${({ position }) => (position === "top" ? "top" : "bottom")}: 0;

  pointer-events: none;

  position: fixed;
  z-index: 11;

  height: 80px;

  background: linear-gradient(
    rgb(247, 247, 252, ${({ position }) => (position === "top" ? 1 : 0)}),
    rgb(247, 247, 252, ${({ position }) => (position === "bottom" ? 1 : 0)})
  );
`;
