import { useEffect, useLayoutEffect, useMemo } from 'react';

import useRequiredParams from '@polyai/common/hooks/useRequiredParams';

import { projectInit } from 'actions';
import api from 'api';
import { useApi } from 'contexts/ApiContext';
import { setAccountId } from 'ducks/account/accountSlice';
import { setProjectId } from 'ducks/project/projectSlice';
import { useAppDispatch } from 'hooks/reduxHooks';
import useAccount from 'hooks/useAccount';
import useAnalytics from 'hooks/useAnalytics/useAnalytics';
import useFeatureSwitch from 'hooks/useFeatureSwitch';
import useProject from 'hooks/useProject';
import { useProjectResources } from 'hooks/useProjectResources';
import useProjects from 'hooks/useProjects';
import useSampleProject from 'hooks/useSampleProjects/useSampleProject';

const useJupiterSettings = () => {
  const { isFeatureOn } = useFeatureSwitch();
  const isSampleProjectEnabled = isFeatureOn('sample_projects');
  const { inSampleProject, isLoadingSampleProject, didSampleProjectError } =
    useSampleProject(isSampleProjectEnabled);
  const { changeMethods } = useApi();

  const {
    account,
    isLoading: isLoadingAccount,
    isError: isAccountError,
  } = useAccount();

  const { projects, isLoadingProjects } = useProjects();

  const { project, isLoadingProject, isProjectError, isProjectForbidden } =
    useProject();

  const {
    isFetchingProjectFeatures,
    didProjectFeaturesError,
    isProjectReadOnly,
  } = useProjectResources();

  useAnalytics();

  const isLoading = inSampleProject
    ? isLoadingSampleProject
    : isLoadingAccount ||
      isLoadingProject ||
      isLoadingProjects ||
      isFetchingProjectFeatures;
  const isError = useMemo(() => {
    if (inSampleProject) {
      return didSampleProjectError;
    }

    const isProjectInAccount =
      account?.active && projects.some((p) => p.id === project?.id);
    return (
      isAccountError ||
      isProjectError ||
      !isProjectInAccount ||
      didProjectFeaturesError
    );
  }, [
    inSampleProject,
    didSampleProjectError,
    account,
    projects,
    project,
    isAccountError,
    isProjectError,
    didProjectFeaturesError,
  ]);

  const isForbidden = inSampleProject
    ? !isSampleProjectEnabled
    : isProjectForbidden;
  const isReadOnly = inSampleProject ? true : isProjectReadOnly;

  useEffect(() => {
    if (inSampleProject) {
      changeMethods({
        startChat: api.startTemplateChat,
        respond: api.templateRespond,
        endChat: api.endTemplateChat,
      });
      return;
    } else {
      changeMethods({
        startChat: api.startDraftChat,
        respond: api.draftRespond,
        endChat: api.endChat,
      });
    }
  }, [inSampleProject, changeMethods]);

  return {
    isLoading,
    isError,
    isForbidden,
    isReadOnly,
    inSampleProject, // FIXME: remove when chat fixed
  };
};

const useProjectInit = () => {
  const { projectId, accountId } = useRequiredParams<{
    projectId: string;
    accountId: string;
  }>();
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    console.warn(
      'routes/index.tsx',
      'Setting projectId in global store. ' +
        'Please update api to pass in projectId via route params instead.',
    );

    dispatch(setAccountId(accountId));
    dispatch(setProjectId(projectId));

    return () => {
      dispatch(projectInit());
    };
  }, [projectId, accountId, dispatch]);

  return useJupiterSettings();
};

export default useProjectInit;
