import { FC, ReactElement, ReactNode, useRef } from 'react';

import Skeleton from '@polyai/ui/components/atoms/Skeleton';
import Tooltip, { TooltipProps } from '@polyai/ui/components/atoms/Tooltip';
import themeSpacing from '@polyai/ui/styles/config/spacing';

import * as Styled from './JupiterCard.styled';

export interface BaseColumnProps {
  icon?: ReactNode;
  title: ReactElement;
  titleTooltip?: ReactNode;
  titleTooltipProps?: Partial<TooltipProps>;
  titleTooltipDisableUnderline?: boolean;
  value: ReactNode;
  valueTooltip?: ReactNode;
  valueTooltipProps?: Partial<TooltipProps>;
  valueTooltipDisableUnderline?: boolean;
  titleValueGap?: keyof typeof themeSpacing;
  isLoading?: boolean;
  className?: string;
}

const BaseColumn: FC<BaseColumnProps> = ({
  icon,
  title,
  titleTooltip,
  titleTooltipProps,
  titleTooltipDisableUnderline,
  titleValueGap,
  value,
  valueTooltip,
  valueTooltipProps,
  valueTooltipDisableUnderline,
  isLoading,
  className,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const Title = titleTooltip ? (
    <Tooltip content={titleTooltip} placement="bottom" {...titleTooltipProps}>
      <Styled.TooltipWrapper $underline={!titleTooltipDisableUnderline}>
        {title}
      </Styled.TooltipWrapper>
    </Tooltip>
  ) : (
    title
  );

  const Value = valueTooltip ? (
    <Tooltip content={valueTooltip} placement="bottom" {...valueTooltipProps}>
      <Styled.TooltipWrapper $underline={!valueTooltipDisableUnderline}>
        {value}
      </Styled.TooltipWrapper>
    </Tooltip>
  ) : (
    value
  );

  return (
    <Styled.CardContainer ref={containerRef} className={className}>
      {icon && <Styled.IconBadge>{icon}</Styled.IconBadge>}
      <Styled.CardTextContainer $gap={titleValueGap}>
        {Title}
        {isLoading ? (
          <Styled.SkeletonContainer>
            <Skeleton />
          </Styled.SkeletonContainer>
        ) : (
          Value
        )}
      </Styled.CardTextContainer>
    </Styled.CardContainer>
  );
};

export default BaseColumn;
