import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const CalendarIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="M10 15a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-1Zm1 4a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1h-1Zm8-4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-1Zm1 4a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1h-1Zm-5.5-4v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1Zm1 4a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1h-1Z" />
    <path
      clipRule="evenodd"
      d="M9 5a1 1 0 0 0-1 1v1H7a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2V6a1 1 0 1 0-2 0v1H10V6a1 1 0 0 0-1-1Zm16 6H7v14h18V11Z"
      fillRule="evenodd"
    />
  </BaseIcon>
);
