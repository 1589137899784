import { z } from 'zod';

import { CompoundType } from '@polyai/common/types/helpers';

import { ContentFilterSchema } from 'utils/validators/settings';
import { Pronunciation } from '../pronunciations/types';

type AssistantVoice = {
  name: string;
  id: string;
  provider: string;
};
type AsrKeyphrase = {
  keyphrase: string;
  level: string;
};

export type AssistantVoiceOptionMetadata = {
  description: string;
  accent: string;
  gender: string;
  descriptive: string;
  suggested_use: string;
  sample_voice: string[];
  tags: string[];
};

export type AssistantVoiceOption = CompoundType<
  AssistantVoice & {
    voice_metadata: AssistantVoiceOptionMetadata;
    provider_voice_id: string;
    config: {
      [key: string]: number;
    };
  }
>;
export type CreateAssistantVoiceOption = Omit<AssistantVoiceOption, 'id'>;

export type TuningSetting = {
  stability: number;
  similarity_boost: number;
  style?: number;
  optimize_streaming_latency?: number;
};

export type AssistantVoiceTuningSetting = {
  [voiceId: string]: TuningSetting;
};

export type VoiceOptionsResponse = {
  voices: AssistantVoiceOption[];
  voice_tuning_settings?: AssistantVoiceTuningSetting;
};

export type ModelOption = {
  id: string;
  name: string;
  provider_model_id: string;
  model_metadata: {
    [key: string]: string;
  };
  config: {
    [key: string]: string;
  };
};

export type ModelOptionsResponse = {
  models: ModelOption[];
};

export enum ContentFilterOption {
  VIOLENCE = 'violence',
  HATE = 'hate',
  SEXUAL = 'sexual',
  SELFHARM = 'self_harm',
  JAILBREAK = 'jailbreak',
}

export enum ContentFilterPrecision {
  LOOSE = 'LOOSE',
  MEDIUM = 'MEDIUM',
  STRICT = 'STRICT',
}

export enum InteractionStyle {
  SONIC = 'sonic',
  SWIFT = 'swift',
  BALANCED = 'balanced',
  PRECISE = 'precise',
}

export type ContentFilter = z.infer<typeof ContentFilterSchema>;

export type AssistantConfig = {
  genai_project_id: string;
  updated_by: string;
  updated_at: string;
  voice_id: string;
  model_id: string;
  default_handoff_id: string;
  config: {
    language_code: string;
    [key: string]: any;
  };
  tts_rules: Pronunciation[];
  asr_keyphrases: AsrKeyphrase[];
  content_filter: {
    config: ContentFilter;
    type: 'AZURE';
  } | null;
  barge_in_enabled: boolean;
  latency_config?: {
    interaction_style: InteractionStyle;
  };
  asr_corrections: AsrCorrection[];
};

export type UpdateAssistantConfig = {
  voice_id?: string;
  model_id?: string;
  default_handoff_id?: string;
  config?: Record<string, string>;
};

export type AsrCorrection = {
  id: string;
  name: string;
  description: string;
  regular_expressions: AsrCorrectionRegularExpression[];
  last_updated: string;
};

export type ReplacementTypeValue = 'full' | 'partial';

export type AsrCorrectionRegularExpression = {
  regular_expression: string;
  replacement: string;
  replacement_type: ReplacementTypeValue;
};

// id is optional when patching, we create an id automatically in the backend.
export type PatchAsrCorrection = Omit<AsrCorrection, 'last_updated' | 'id'> & {
  id?: string;
  last_updated?: string;
};

export type TranscriptCorrectionMatchType = {
  full: {
    label: string;
    value: 'full';
  };
  partial: {
    label: string;
    value: 'partial';
  };
};
