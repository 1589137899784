import React from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const RepeatIcon: React.FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="M21 7L15 4V6H8C6.34315 6 5 7.34315 5 9V23C5 24.6569 6.34315 26 8 26H9.5C10.0523 26 10.5 25.5523 10.5 25C10.5 24.4477 10.0523 24 9.5 24H8C7.44772 24 7 23.5523 7 23V9C7 8.44772 7.44772 8 8 8H15V10L21 7Z" />
    <path d="M26 23C26 23.5523 25.5523 24 25 24H18V22L12 25L18 28V26H25C26.6569 26 28 24.6569 28 23V9C28 7.34315 26.6569 6 25 6H23.5C22.9477 6 22.5 6.44772 22.5 7C22.5 7.55228 22.9477 8 23.5 8H25C25.5523 8 26 8.44772 26 9V23Z" />
  </BaseIcon>
);
