import ENDPOINTS from 'api/endpoints';
import { doGet, doPost } from 'api/request';
import { CreateAssistantVoiceOption, LOGGER_TYPE } from 'api/types';
import { generateNextPath } from 'lib/routeHelper';
import { TTSProviderVoiceConfig } from './types';

export const getTTSProviderVoiceConfig = (
  provider: string,
  voiceId: string,
) => {
  return doGet<TTSProviderVoiceConfig>(
    generateNextPath(ENDPOINTS.PROVIDER_VOICE_CONFIG, {
      provider,
      voiceId,
    }),
    LOGGER_TYPE.JUPITER,
    { version: 'v1' },
  );
};

export const createVoice = (
  accountId: string,
  request: CreateAssistantVoiceOption,
) => {
  return doPost<CreateAssistantVoiceOption>(
    generateNextPath(ENDPOINTS.VOICES, { accountId }),
    LOGGER_TYPE.JUPITER,
    request,
    { version: 'v1' },
  );
};
