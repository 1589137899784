import { Ref } from "react";

import ReactSelect, {
  CSSObjectWithLabel,
  Props as ReactSelectProps,
  SelectInstance,
  StylesConfig,
} from "react-select";
import { useTheme } from "styled-components";

import { TriangleArrowDownIcon } from "components/atoms/Icons";
import InputWrapper from "components/atoms/InputWrapper";
import { Title } from "components/atoms/Text";

import { ColorMapping } from "styles/config/colors";

import * as Styled from "./Select.styled";

const getDefaultStyles = (
  colors: ColorMapping
): { [key: string]: CSSObjectWithLabel } => ({
  control: {
    padding: "5px 10px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: colors.borderStrong,
    borderRadius: "5px",
    outline: "none",
    boxShadow: "none",
    ":focus": {
      borderColor: colors.borderPrimary,
    },
  },
  menuList: {
    border: `1px solid ${colors.borderPrimary}`,
    margin: 0,
    gap: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  menu: {
    margin: 0,
    boxShadow: "none",
    zIndex: 2,
  },
  option: {
    borderRadius: 0,
    padding: "15px 20px",
    color: "black",
  },
  dropdownIndicator: {
    transition: "color 150ms, rotate 150ms",
  },
  indicatorSeparator: {
    display: "none",
  },
  placeholder: {
    "& h3": {
      fontSize: "14px",
    },
  },
});

export type OptionType = {
  label: string;
  value: string;
};

export interface SelectProps<
  OT extends OptionType = OptionType,
  isMulti extends boolean = boolean
> extends ReactSelectProps<OT, isMulti> {
  selectRef?: Ref<SelectInstance<OT, isMulti>>;
  errors?: string[];
  label?: string;
  help?: string;
  readonly?: boolean;
  "data-test-id"?: string;
  "data-dd-action"?: string;
  fullWidth?: boolean;
  portal?: boolean;
}

const DefaultFormatOption = (option: OptionType) => {
  return <Title>{option.label}</Title>;
};

const CustomLoadingIndicator = () => {
  return <Styled.Loader spinnerText="" spinner />;
};

const Select = <OT extends OptionType, isMulti extends boolean>({
  components,
  styles,
  menuPlacement,
  placeholder,
  formatOptionLabel,
  selectRef,
  errors = [],
  label,
  help,
  value,
  isLoading,
  loadingMessage,
  readonly = false,
  "data-test-id": dataTestId,
  "data-dd-action": ddActionName,
  fullWidth,
  portal,
  ...props
}: SelectProps<OT, isMulti>): JSX.Element => {
  const {
    activeColorScheme: { colors },
  } = useTheme();

  const defaultStyles = getDefaultStyles(colors);

  // NB: the styling will fail if menuPlacement="auto" and the menu flips
  // to top. At the moment I can't see a way of finding the placement of
  // the menu for custom styling
  const customStyle: StylesConfig<OT, isMulti> = {
    ...styles,
    dropdownIndicator: (base) => ({
      ...base,
      ...defaultStyles.dropdownIndicator,
    }),
    control: (base, props) => {
      let borderColor = props.menuIsOpen
        ? colors.borderPrimary
        : colors.borderStrong;
      if (errors.length) {
        borderColor = colors.borderDanger;
      }
      if (props.isFocused) {
        borderColor = colors.borderPrimary;
      }
      const border: CSSObjectWithLabel = {};
      const hover: CSSObjectWithLabel = { borderColor };
      if (props.menuIsOpen) {
        if (props.selectProps.menuPlacement === "bottom") {
          border.borderBottomColor = hover.borderBottomColor = "white";
          border.borderRadius = "5px 5px 0 0";
        }
        if (props.selectProps.menuPlacement === "top") {
          border.borderTopColor = hover.borderTopColor = "white";
          border.borderRadius = "0 0 5px 5px";
        }
      }

      return {
        ...base,
        ...defaultStyles.control,
        ...border,
        borderColor,
        "&:hover": hover,
        "& .triangleArrowDownIcon svg": {
          transform: props.menuIsOpen ? "rotate(180deg)" : "none",
        },
        cursor: "pointer",
        ...(styles?.control ? styles.control(base, props) : {}),
      } as CSSObjectWithLabel;
    },
    option: (base, props) => {
      const border: CSSObjectWithLabel = {};
      if (props.selectProps.menuPlacement === "bottom") {
        border.borderTop = `1px solid ${colors.borderStrong}`;
      }
      if (props.selectProps.menuPlacement === "top") {
        border.borderBottom = `1px solid ${colors.borderStrong}`;
      }
      return {
        ...base,
        ...defaultStyles.option,
        backgroundColor: props.isFocused ? colors.backgroundPage : "white",
        cursor: "pointer",
        ...border,
        ...(styles?.option ? styles.option(base, props) : {}),
      } as CSSObjectWithLabel;
    },

    menu: (base, props) => {
      return {
        ...base,
        ...defaultStyles.menu,
        ...(styles?.menu ? styles.menu(base, props) : {}),
      };
    },
    menuList: (base, props) => {
      const border: CSSObjectWithLabel = {};
      if (errors.length) {
        border.borderColor = colors.borderDanger;
      }
      if (props.selectProps.menuPlacement === "bottom") {
        border.borderTopColor = colors.borderStrong;
      }
      if (props.selectProps.menuPlacement === "top") {
        border.borderBottom = colors.borderStrong;
      }

      return {
        ...base,
        ...defaultStyles.menuList,
        "&:first-of-type": {
          borderRadius:
            props.selectProps.menuPlacement === "top"
              ? "5px 5px 0 0"
              : "0 0 5px 5px",
        },
        ...border,
        ...(styles?.menuList ? styles.menuList(base, props) : {}),
      } as CSSObjectWithLabel;
    },
    indicatorSeparator: (base, props) => {
      return {
        ...base,
        ...defaultStyles.indicatorSeparator,
        ...(styles?.indicatorSeparator
          ? styles.indicatorSeparator(base, props)
          : {}),
      };
    },
    placeholder: (base, props) => ({
      ...base,
      ...defaultStyles.placeholder,
      ...(styles?.placeholder ? styles.placeholder(base, props) : {}),
    }),
  };

  const DefaultDropdownIndicator = (props: any) => {
    return (
      <Styled.DropdownIndicator {...props}>
        <TriangleArrowDownIcon
          aria-hidden={readonly ? true : false}
          opacity={readonly ? 0 : 1}
        />
      </Styled.DropdownIndicator>
    );
  };

  return (
    <InputWrapper
      data-test-id={dataTestId}
      errors={errors}
      fullWidth={fullWidth}
      help={help}
      label={label}
      portal={portal}
      labelAsDiv
    >
      <Styled.SelectWrapper
        $fullWidth={fullWidth}
        data-dd-action={ddActionName}
      >
        <ReactSelect
          ref={selectRef}
          components={{
            DropdownIndicator: DefaultDropdownIndicator,
            LoadingIndicator: CustomLoadingIndicator,
            ...(components || {}),
          }}
          data-dd-action="TESTING"
          formatOptionLabel={formatOptionLabel || DefaultFormatOption}
          isLoading={isLoading}
          isSearchable={false}
          loadingMessage={loadingMessage}
          menuIsOpen={readonly ? false : undefined}
          menuPlacement={menuPlacement}
          placeholder={<Title>{placeholder || "Select..."}</Title>}
          styles={customStyle}
          value={value}
          {...props}
        />
      </Styled.SelectWrapper>
    </InputWrapper>
  );
};

export default Select;
