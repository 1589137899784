import Collapsable from '@polyai/ui/components/atoms/Collapsable';
import { Title } from '@polyai/ui/components/atoms/Text';
import ScreenHeader from '@polyai/ui/components/molecules/ScreenHeader';

import { JupiterCardList } from 'components/molecules/JupiterCard';

import useAsrAnnotationsTasks from 'hooks/useAsrAnnotations/useAsrAnnotationsTasks';
import AsrAnnotationTaskCard from 'screens/AsrAnnotations/components/AsrAnnotationTaskCard';
import withPermissions from 'screens/withPermissions';

const AsrAnnotationsScreen = () => {
  const { asrAnnotationTasks } = useAsrAnnotationsTasks();

  const completedTasks = asrAnnotationTasks.filter(
    (task) => task.summary.pending === 0,
  );

  const pendingTasks = asrAnnotationTasks
    .filter((task) => task.summary.pending > 0)
    // Sort by due date, nearest first
    .sort(
      (a, b) => new Date(a.due_by).getTime() - new Date(b.due_by).getTime(),
    );

  return (
    <>
      <ScreenHeader
        description="All ASR annotation tasks assigned to your email"
        screenTitle="ASR annotations"
      />
      <Collapsable
        border={false}
        customHeader={<Title>Pending tasks</Title>}
        parentContentOpen
      >
        <JupiterCardList>
          {pendingTasks.map((asrAnnotationTask) => (
            <AsrAnnotationTaskCard
              key={asrAnnotationTask.task_id}
              asrAnnotationTask={asrAnnotationTask}
            />
          ))}
        </JupiterCardList>
      </Collapsable>
      <Collapsable customHeader={<Title>Completed tasks</Title>}>
        <JupiterCardList>
          {completedTasks.map((asrAnnotationTask) => (
            <AsrAnnotationTaskCard
              key={asrAnnotationTask.task_id}
              asrAnnotationTask={asrAnnotationTask}
            />
          ))}
        </JupiterCardList>
      </Collapsable>
    </>
  );
};

export default withPermissions(
  AsrAnnotationsScreen,
  undefined,
  'internal_asr_annotations_tool',
);
