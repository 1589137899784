import React, {
  ComponentPropsWithRef as ComponentProps,
  forwardRef,
  memo,
  useMemo,
} from "react";

import { z } from "zod";

import ConditionalWrapper from "@polyai/common/utils/conditionalWrapper";

import Clickable from "../Clickable";
import { BaseIcon } from "../Icons/BaseIcon";

import * as Styled from "./Button.styled";

const emojiSchema = z.string().emoji();

export enum ButtonVariant {
  BRAND = "brand",
  PRIMARY = "primary",
  SECONDARY = "secondary",
  GHOST = "ghost",
  SUCCESS = "success",
  DANGER = "danger",
  MAKE_IT_POP = "make-it-pop",
}

export type ButtonLabelSize = "small" | "regular";

interface BaseProps extends ComponentProps<"button"> {
  label: string;
  variant?: keyof typeof ButtonVariant;
  inverse?: boolean;
  size?: ButtonLabelSize;
  loading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  to?: string;
}

export type ButtonIcon = typeof BaseIcon | string;

export interface ButtonWithIconsProps extends BaseProps {
  // limiting the type to only accept Icon or emoji
  startAdornment?: ButtonIcon;
  endAdornment?: ButtonIcon;
}

export interface ButtonWithIconOnlyProps extends BaseProps {
  Icon: ButtonIcon;
}

export type ButtonProps = ButtonWithIconsProps | ButtonWithIconOnlyProps;

const Button = memo(
  forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const Icon = "Icon" in props ? props.Icon : undefined;
    const {
      label,
      variant = "PRIMARY",
      inverse = false,
      size = "regular",
      loading,
      disabled,
      fullWidth,
      to,
      ...restProps
    } = props;

    const iconColor = useMemo(() => {
      if (disabled && !inverse) {
        return "iconDisabled";
      } else if (disabled && inverse) {
        return "iconInverseDisabled";
      } else if (inverse) {
        return Styled.variantToInverseButtonIconColor[variant];
      }

      return Styled.variantToButtonIconColor[variant];
    }, [disabled, inverse, variant]);

    const renderAdornment = (key: "start" | "end") => {
      const adornment =
        key === "start"
          ? "startAdornment" in props && props.startAdornment
          : "endAdornment" in props && props.endAdornment;
      if (!adornment) {
        return null;
      }
      if (typeof adornment === "function") {
        const StartIcon = adornment;
        return <StartIcon color={iconColor} />;
      } else if (typeof adornment === "string") {
        try {
          emojiSchema.parse(adornment);
          return adornment;
        } catch (e) {
          console.error(
            `Only emojis are supported as string \`${
              key === "start" ? "startAdornment" : "endAdornment"
            }\` for Button`
          );
        }
      }
      return null;
    };

    const a11yProps = {
      "aria-disabled": loading || disabled,
      "aria-label": loading ? "Loading" : label,
    };

    /* The new version of the deprecated IconButton */
    if (Icon) {
      return (
        <Styled.IconOnlyButtonWrapper
          ref={ref}
          $inverse={inverse}
          $loading={loading}
          $size={size}
          $variant={variant}
          disabled={disabled}
          {...a11yProps}
          {...restProps}
        >
          <Styled.ButtonContentContainer
            $show={loading ? false : true}
            $size="small"
          >
            <Icon color={iconColor} />
          </Styled.ButtonContentContainer>
          <Styled.LoadingIcon $show={!!loading} />
        </Styled.IconOnlyButtonWrapper>
      );
    }

    const startAdornment = renderAdornment("start");
    const endAdornment = renderAdornment("end");
    const hasAdornment = Boolean(startAdornment || endAdornment);

    return (
      <ConditionalWrapper
        condition={!!to}
        wrapper={(children) => <Clickable to={to}>{children}</Clickable>}
      >
        <Styled.ButtonWrapper
          ref={ref}
          $fullWidth={fullWidth}
          $inverse={inverse}
          $loading={loading}
          $size={size}
          $variant={variant}
          disabled={disabled}
          {...a11yProps}
          {...restProps}
        >
          <Styled.ButtonContentContainer
            $show={loading ? false : true}
            $size={size}
          >
            {startAdornment}
            <Styled.ButtonLabel
              $disabled={disabled}
              $hasAdornment={hasAdornment}
              $size={size}
            >
              {label}
            </Styled.ButtonLabel>
            {endAdornment}
          </Styled.ButtonContentContainer>
          <Styled.LoadingIcon $show={loading ? true : false} />
        </Styled.ButtonWrapper>
      </ConditionalWrapper>
    );
  })
);

export default Button;
