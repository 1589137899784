import ENDPOINTS from 'api/endpoints';
import { doDelete, doGet, doPost, doPut } from 'api/request';
import {
  CreateHandoffRequest,
  EditHandoffRequest,
  HandoffResponse,
  HandoffsResponse,
} from 'api/resources/handoff/types';
import { LOGGER_TYPE } from 'api/types';
import { pick } from 'lib/miscHelper';
import { generateNextPath } from 'lib/routeHelper';
import { store } from 'reduxStore';

export const getHandoffs = () => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doGet<HandoffsResponse>(
    generateNextPath(ENDPOINTS.HANDOFFS, {
      accountId,
      projectId,
    }),
    LOGGER_TYPE.JUPITER,
    { version: 'v2' },
  );
};

export const createHandoffConfig = (request: CreateHandoffRequest) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doPost<HandoffResponse>(
    generateNextPath(ENDPOINTS.HANDOFFS, { accountId, projectId }),
    LOGGER_TYPE.JUPITER,
    request,
    { version: 'v2' },
  );
};

export const editHandoffConfig = ({
  id: handoffId,
  ...request
}: EditHandoffRequest) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doPut<HandoffResponse>(
    generateNextPath(ENDPOINTS.HANDOFF, {
      accountId,
      projectId,
      handoffId: handoffId ?? null,
    }),
    LOGGER_TYPE.JUPITER,
    pick(
      request,
      'name',
      'description',
      'sip_type',
      'sip_config',
      'sip_headers',
    ),
    { version: 'v2' },
  );
};

export const deleteHandoffConfig = ({ handoffId }: { handoffId: string }) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doDelete(
    generateNextPath(ENDPOINTS.HANDOFF, {
      accountId,
      projectId,
      handoffId,
    }),
    LOGGER_TYPE.JUPITER,
    {},
    { version: 'v2' },
  );
};

export const updateDefaultHandoff = ({ handoffId }: { handoffId: string }) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doPut<{ handoffs: HandoffResponse[] }>(
    generateNextPath(ENDPOINTS.DEFAULT_HANDOFF, {
      accountId,
      projectId,
    }),
    LOGGER_TYPE.JUPITER,
    {
      handoff_id: handoffId,
    },
    { version: 'v2' },
  );
};
