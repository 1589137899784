import React, {
  Fragment,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";

import Animation from "../Animation";
import { ChevronUpIcon } from "../Icons";

import * as Styled from "./Collapsable.styled";

type BaseCollapsableProps = {
  children?: ReactNode;
  disabled?: boolean;
  parentContentOpen?: boolean;
  hideIcon?: boolean;
  wrapperStyles?: string;
  headerStyles?: string;
  headerHeight?: string;
  border?: boolean;
};

type TitleProps = BaseCollapsableProps & {
  title: ReactNode;
  customHeader?: never;
};

type CustomHeaderProps = BaseCollapsableProps & {
  title?: never;
  customHeader: ReactElement;
};

export type CollapsableProps = TitleProps | CustomHeaderProps;

const Collapsable = ({
  children,
  title,
  disabled = false,
  parentContentOpen = false,
  hideIcon = false,
  customHeader,
  headerHeight,
  border = true,
  ...props
}: CollapsableProps) => {
  const [isCollapsableContentOpen, setIsCollapsableContentOpen] =
    useState(false);

  useEffect(() => {
    setIsCollapsableContentOpen(parentContentOpen);
  }, [parentContentOpen]);

  const handleOnClick = useCallback(() => {
    if (!disabled && children) {
      setIsCollapsableContentOpen(!isCollapsableContentOpen);
    }
  }, [isCollapsableContentOpen, disabled, children]);

  return (
    <Styled.Wrapper $border={border} {...props}>
      <Styled.Header
        $disabled={disabled}
        $noChildren={children === undefined}
        data-test-id="collapsable-header"
        headerHeight={headerHeight}
        onClick={handleOnClick}
      >
        {customHeader || <Fragment>{title}</Fragment>}
        {!hideIcon && (
          <Styled.ToggleFolderIcon $isOpen={isCollapsableContentOpen}>
            <ChevronUpIcon />
          </Styled.ToggleFolderIcon>
        )}
      </Styled.Header>
      <Animation
        data-test-id="collapsable-content"
        direction="vertical"
        visible={isCollapsableContentOpen}
      >
        {isCollapsableContentOpen && !disabled && children}
      </Animation>
    </Styled.Wrapper>
  );
};

export default Collapsable;
