import { useQuery } from '@tanstack/react-query';

import useRequiredParams from '@polyai/common/hooks/useRequiredParams';

import api from 'api';
import { AccountResponse } from 'api/resources/accounts/types';
import useSampleProject from './useSampleProjects/useSampleProject';

const useAccount = () => {
  const { accountId } = useRequiredParams<{ accountId: string }>();
  const { inSampleProject } = useSampleProject();

  const {
    data: account,
    isLoading,
    isError,
  } = useQuery<AccountResponse, Error>(
    [accountId, 'account'],
    () => api.getAccount(accountId),
    {
      retry: false,
      enabled: !inSampleProject && !!accountId,
    },
  );

  return {
    account,
    isLoading,
    isError,
  };
};

export default useAccount;
