import { LuzmoAuthToken } from '@polyai/common/api/resources/luzmo/types';

import { doGet, doPost } from 'api/request';
import {
  AnalyticsIntegration,
  Interval,
  LuzmoAuthTokenPayload,
  SafetyDataResponse,
  StandardisedDataResponse,
} from 'api/resources/analytics/types';
import { LOGGER_TYPE } from 'api/types';
import { store } from 'reduxStore';

export const getLuzmoAuthToken = (
  integrationId?: string,
  suborganisation: string = 'PolyAI',
  theme?: { [style: string]: any },
  css: string = '',
) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  const payload: LuzmoAuthTokenPayload = {
    suborganisation,
    theme,
    css,
    metadata: { account_id: accountId, project_id: projectId },
  };

  if (integrationId) {
    payload.integration_id = integrationId;
  }

  return doPost<LuzmoAuthToken>(
    `accounts/${accountId}/projects/${projectId}/analytics/auth`,
    LOGGER_TYPE.ANALYTICS,
    payload,
  );
};

export const getIntegration = () => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doGet<AnalyticsIntegration>(
    `accounts/${accountId}/projects/${projectId}/analytics/integrations`,
    LOGGER_TYPE.ANALYTICS,
  );
};

export const getStandardisedData = (
  interval: Interval,
  startTime: string,
  endTime: string,
) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doGet<StandardisedDataResponse>(
    `accounts/${accountId}/projects/${projectId}/analytics/standardized-data`,
    LOGGER_TYPE.ANALYTICS,
    {
      params: {
        interval,
        start_time: startTime,
        end_time: endTime,
      },
    },
  );
};

export const getSafetyData = (
  interval: Interval,
  startTime: string,
  endTime: string,
) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doGet<SafetyDataResponse>(
    `accounts/${accountId}/projects/${projectId}/analytics/standardized-data/safety`,
    LOGGER_TYPE.ANALYTICS,
    {
      params: {
        interval,
        start_time: startTime,
        end_time: endTime,
      },
    },
  );
};
