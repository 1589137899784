import {
  AssistantConfig,
  ContentFilterPrecision,
  TranscriptCorrectionMatchType,
} from './types';

export const DEFAULT_CONTENT_FILTER: AssistantConfig['content_filter'] = {
  config: {
    violence: {
      is_active: true,
      precision: ContentFilterPrecision.MEDIUM,
    },
    hate: {
      is_active: true,
      precision: ContentFilterPrecision.MEDIUM,
    },
    sexual: {
      is_active: true,
      precision: ContentFilterPrecision.LOOSE,
    },
    self_harm: {
      is_active: true,
      precision: ContentFilterPrecision.MEDIUM,
    },
  },
  type: 'AZURE',
};

export const TRANSCRIPT_CORRECTION_REPLACEMENT_TYPE: TranscriptCorrectionMatchType =
  {
    full: {
      label: 'full transcript',
      value: 'full',
    },
    partial: {
      label: 'substring',
      value: 'partial',
    },
  } as const;
