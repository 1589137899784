import React, { createContext, useCallback, useContext, useState } from 'react';

import api from 'api';

export type ApiMethods = keyof typeof api;
export type ApiMethodFn<T extends ApiMethods> = (typeof api)[T];

interface ApiContextType {
  methods: {
    [K in ApiMethods]: ApiMethodFn<K>;
  };
  changeMethods: (methods: Partial<ApiContextType['methods']>) => void;
}

const ApiContext = createContext<ApiContextType>({
  changeMethods: () => {},
  methods: api,
});

export const ApiProvider: React.FC<{
  methods?: ApiContextType['methods'];
  children?: React.ReactNode;
}> = ({ children, ...rest }) => {
  const [methods, setMethods] = useState({
    ...api,
    ...rest.methods,
  });

  const changeMethods: ApiContextType['changeMethods'] = useCallback((m) => {
    setMethods((prev) => {
      return {
        ...prev,
        ...m,
      };
    });
  }, []);

  return (
    <ApiContext.Provider
      value={{
        methods,
        changeMethods,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = () => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error('useApi must be used within an ApiProvider');
  }

  return context;
};
