import React, { FC } from 'react';

import { Outlet } from 'react-router-dom';

import useRequiredParams from '@polyai/common/hooks/useRequiredParams';
import AccountsSidebar, {
  SidebarAccount,
} from '@polyai/ui/components/molecules/AccountsSidebar';

import LandingNav from 'components/molecules/LandingNav';

import { useAppSelector } from 'hooks/reduxHooks';
import useAccounts from 'hooks/useAccounts';

import * as Styled from './LandingLayout.styled';

const isPersonalAccount = (name: string, userEmail?: string) =>
  userEmail && name.includes(userEmail);

const getSidebarAccountName = (name: string, userEmail?: string) => {
  if (isPersonalAccount(name, userEmail)) {
    return 'My Workspace';
  }

  return name;
};

const MAX_ABBREVIATION_LENGTH = 3;
export const getSidebarAccountAbbreviation = (
  name: string,
  userEmail?: string,
): string => {
  if (isPersonalAccount(name, userEmail)) {
    return 'MW';
  }
  if (name === 'PLATFORM') {
    return 'PLT';
  }
  if (name === 'PolyAIDemos') {
    return 'DMO';
  }

  const finalName = name.includes('@poly') ? name.split('@')[0] : name;

  const getInitials = (pattern: RegExp) =>
    (finalName.match(pattern) ?? [])
      .slice(0, MAX_ABBREVIATION_LENGTH)
      .join('')
      .toUpperCase();

  const wordInitials = getInitials(/\b[A-Za-z]/g);
  if (wordInitials.length >= 2) {
    return wordInitials;
  }

  const capitalLetters = getInitials(/\b[A-Za-z]|\B[A-Z]/g);
  if (capitalLetters.length >= 2) {
    return capitalLetters;
  }

  const consonants = getInitials(/^[a-zA-Z]|[^aeiouAEIOU]/g);

  if (consonants.length >= 2) {
    return consonants;
  }

  return finalName.slice(0, MAX_ABBREVIATION_LENGTH).toUpperCase();
};

const LandingLayout: FC = () => {
  const { accountId } = useRequiredParams<{ accountId: string }>();

  const { accounts } = useAccounts();

  const user = useAppSelector((state) => state.auth.user);

  const sidebarAccounts: SidebarAccount[] = accounts.map((account) => ({
    id: account.id,
    name: getSidebarAccountName(account.name, user?.email),
    abbreviation: getSidebarAccountAbbreviation(account.name, user?.email),
  }));

  return (
    <>
      <LandingNav />
      <Styled.PageWrapper>
        {accounts.length > 1 && (
          <AccountsSidebar
            accounts={sidebarAccounts}
            activeAccountId={accountId}
          />
        )}
        <Outlet />
      </Styled.PageWrapper>
    </>
  );
};

export default LandingLayout;
