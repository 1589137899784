import { useCallback, useMemo } from 'react';

import { ClientEnvironment } from '@polyai/common/constants/global.constants';

import { useAppSelector } from 'hooks/reduxHooks';

export const DEPLOYMENTS_MUTATION_KEY = 'deployments';
export const ACTIVE_QUERY_KEY = 'active';

export const useDeploymentsQueryKey = (clientEnv?: ClientEnvironment) => {
  const accountId = useAppSelector((state) => state.account.id);
  const projectId = useAppSelector((state) => state.project.id);

  return {
    queryKey: useMemo(
      () => [accountId, projectId, DEPLOYMENTS_MUTATION_KEY, clientEnv!],
      [accountId, projectId, clientEnv],
    ),
    getQueryKey: useCallback(
      (clientEnv: ClientEnvironment) => [
        accountId,
        projectId,
        'deployments',
        clientEnv,
      ],
      [accountId, projectId],
    ),
  };
};

export const useActiveDeploymentsQueryKey = () => {
  const accountId = useAppSelector((state) => state.account.id);
  const projectId = useAppSelector((state) => state.project.id);

  return useMemo(
    () => [accountId, projectId, DEPLOYMENTS_MUTATION_KEY, ACTIVE_QUERY_KEY],
    [accountId, projectId],
  );
};
