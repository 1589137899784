import { useAuth0 } from '@auth0/auth0-react';

import { Logo } from '@polyai/ui/components/atoms/Logo';
import { Body, Title } from '@polyai/ui/components/atoms/Text';

import { useAppSelector } from 'hooks/reduxHooks';
import BottomDots from './bottomDots';
import TopDots from './topDots';

import * as Styled from './VerifyEmail.styled';

const VerifyEmail = () => {
  const { user } = useAuth0();
  const logout = useAppSelector((state) => state.auth.logout);

  return (
    <>
      <Styled.DotsContainer>
        <TopDots />
        <BottomDots />
      </Styled.DotsContainer>
      <Styled.VerifyEmailContainer>
        <Logo
          dotsColor="iconPrimary"
          height={52}
          textColor="iconPrimary"
          width={122}
        />
        <Styled.VerifyEmailContent>
          <Title>Please verify your account</Title>
          <Body>
            To use PolyAI Agent Studio, please click on the verification link in
            the email we sent to <strong>{user?.email}</strong>
          </Body>
          <Body>
            Once you have done this{' '}
            <Styled.UnderlinedButton
              hoverSize="xl"
              hoverTargetColor="borderSubtle"
              hoverTarget
              onClick={() => logout()}
            >
              return to login
            </Styled.UnderlinedButton>{' '}
            and try again.
          </Body>
        </Styled.VerifyEmailContent>
      </Styled.VerifyEmailContainer>
    </>
  );
};

export default VerifyEmail;
