import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import ConditionalWrapper from "@polyai/common/utils/conditionalWrapper";
import { safeResizeObserver } from "@polyai/common/utils/safeResizeObserver";

import { BaseIcon } from "../Icons/BaseIcon";
import InputWrapper, { InputWrapperProps } from "../InputWrapper";

import * as Styled from "./Input.styled";

export type InputProps = React.InputHTMLAttributes<
  HTMLInputElement | HTMLTextAreaElement
> & {
  label?: ReactNode;
  errors?: string[];
  rows?: number;
  loading?: boolean;
  autoExpand?: boolean;
  fullWidth?: boolean;
  showErrorAsTooltip?: boolean;
  showCharacterCount?: boolean;
  Icon?: typeof BaseIcon;
  hint?: ReactNode;
  textArea?: boolean;
  isKeyboardSafe?: boolean;
} & InputWrapperProps;

export const Input = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  InputProps
>(
  (
    {
      label,
      errors = [],
      rows = 1,
      maxLength,
      className,
      loading,
      autoExpand,
      fullWidth,
      showErrorAsTooltip,
      showCharacterCount,
      defaultValue,
      onChange,
      Icon,
      hint,
      textArea,
      help,
      rightLabel,
      "data-dd-action": ddActionName,
      isKeyboardSafe = false,
      portal = false,
      ...props
    },
    ref
  ) => {
    const [length, setLength] = useState(defaultValue?.toString().length || 0);
    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

    const [hintWidth, setHintWidth] = useState<number>();
    const hintRef = useRef<HTMLDivElement | null>(null);

    const updateLength = useCallback(
      (value?: typeof defaultValue) => {
        setLength(value?.toString()?.length || 0);

        if (
          !CSS.supports("field-sizing", "content") &&
          textAreaRef.current &&
          autoExpand
        ) {
          const currentTextRef = textAreaRef.current;

          // store the page scroll position to return to this once
          // text area is resized
          const pageScrollTop = document.documentElement.scrollTop;

          currentTextRef.style.height = "0px";

          const textAreaScrollHeight = currentTextRef.scrollHeight;
          currentTextRef.style.height = textAreaScrollHeight + "px";

          window.scrollTo(0, pageScrollTop);
        }
      },
      [autoExpand]
    );

    useEffect(() => {
      if (props.value !== textAreaRef.current?.value) {
        updateLength(props.value);
      }
      updateLength(textAreaRef.current?.value);
    }, [updateLength, props.value]);

    // HACK fix to make sure we capture the latest scrollHeight on first load
    useLayoutEffect(() => {
      if (
        !CSS.supports("field-sizing", "content") &&
        textAreaRef.current &&
        autoExpand
      ) {
        const setSize = () => {
          const currentTextRef = textAreaRef.current;

          if (!currentTextRef) {
            return;
          }

          setTimeout(() => {
            const scrollHeight = currentTextRef.scrollHeight;
            currentTextRef.style.height = scrollHeight + "px";
          }, 100);
        };

        const sizeObserver = safeResizeObserver(() => {
          setSize();
        });

        setSize();
        sizeObserver.observe(textAreaRef.current);

        return () => {
          textAreaRef.current && sizeObserver.unobserve(textAreaRef.current);
        };
      }
    }, [autoExpand]);

    useLayoutEffect(() => {
      if (hintRef.current && !props.hidden) {
        setHintWidth(hintRef.current?.offsetWidth);
      }
    }, [props.hidden, hint]);

    return (
      <InputWrapper
        afterErrors={
          showCharacterCount &&
          maxLength && (
            <Styled.MaxLengthCaption $withError={length > maxLength}>
              {length}/{maxLength}
            </Styled.MaxLengthCaption>
          )
        }
        errors={errors}
        fullWidth={fullWidth}
        help={help}
        label={label}
        portal={portal}
        rightLabel={rightLabel}
        showErrorAsTooltip={showErrorAsTooltip}
      >
        {rows > 1 || textArea ? (
          <Styled.TextArea
            ref={(input) => {
              textAreaRef.current = input;
              if (typeof ref === "function") {
                ref(input);
              } else if (ref) {
                ref.current = input;
              }
            }}
            $autoExpand={autoExpand}
            $withError={errors?.length > 0}
            className={className}
            data-dd-action={ddActionName}
            defaultValue={defaultValue}
            isKeyboardSafe={isKeyboardSafe}
            maxLength={maxLength}
            readOnly={props.readOnly}
            rows={rows}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              updateLength(e.target.value);
              if (onChange) {
                onChange(e);
              }
            }}
            {...props}
          />
        ) : (
          <ConditionalWrapper
            condition={!!autoExpand}
            wrapper={(children) => (
              <Styled.AutoExpandInputWrapper
                className={className}
                data-value={
                  props.value ? props.value + "-" : props.placeholder + "-"
                }
              >
                {children}
              </Styled.AutoExpandInputWrapper>
            )}
          >
            <Styled.InputContainer $fullWidth={fullWidth}>
              <Styled.Input
                ref={ref as RefObject<HTMLInputElement>}
                $autoExpand={autoExpand}
                $fullWidth={fullWidth}
                $hasIcon={!!Icon}
                $hintWidth={hintWidth}
                $withError={errors?.length > 0}
                aria-readonly={props.readOnly}
                className={className}
                data-dd-action={ddActionName}
                defaultValue={defaultValue}
                isKeyboardSafe={isKeyboardSafe}
                maxLength={maxLength}
                readOnly={props.readOnly}
                style={Icon ? { paddingLeft: 40 } : undefined}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setLength(e.target.value.length);
                  if (onChange) {
                    onChange(e);
                  }
                }}
                {...props}
              />
              {Icon && (
                <Styled.IconWrapper>
                  <Icon />
                </Styled.IconWrapper>
              )}
              {loading && <Styled.LoadingSpinner size={16} strokeWidth={1} />}
              {hint && (
                <Styled.HintWrapper ref={hintRef}>{hint}</Styled.HintWrapper>
              )}
            </Styled.InputContainer>
          </ConditionalWrapper>
        )}
      </InputWrapper>
    );
  }
);

export default Input;
