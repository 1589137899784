import styled from "styled-components";

import { PersistentScrollbarStyles } from "styles/components/scrollbar";
import themeColors from "styles/config/colors";
import themeSpacing from "styles/config/spacing";

export const PopoverContet = styled.div`
  max-width: calc(100dvw - ${themeSpacing.sm3} - ${themeSpacing.sm3});
  max-height: calc(100dvh - ${themeSpacing.sm3} - ${themeSpacing.sm3});

  background: ${themeColors.backgroundInverse};
  border: 1px solid;
  border-radius: ${themeSpacing.radiusMedium};

  ${PersistentScrollbarStyles};
`;

export const PopoverListItem = styled.button`
  display: block;
  width: 100%;
  color: ${themeColors.textSecondaryInverse};

  &:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  &:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }

  &:disabled {
    cursor: default;
    color: currentcolor;
    opacity: 0.5;
  }

  &:active {
    color: ${themeColors.textSecondaryInverse};
  }

  &:hover:not([disabled]),
  &:active:not([disabled]),
  &:focus:not([disabled]) {
    background-color: ${themeColors.backgroundAccentInverse};
  }
`;
