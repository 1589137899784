import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const CompareIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="M9.9 13.2L11.775 15.7C11.9083 15.8778 11.9083 16.1222 11.775 16.3L9.9 18.8C9.6117 19.1844 9 18.9805 9 18.5V13.5C9 13.0195 9.6117 12.8156 9.9 13.2Z" />
    <path
      clipRule="evenodd"
      d="M14 4.5C14 4.22386 14.2239 4 14.5 4H15.5C15.7761 4 16 4.22386 16 4.5V27.5C16 27.7761 15.7761 28 15.5 28H14.5C14.2239 28 14 27.7761 14 27.5V26H6C4.89543 26 4 25.1046 4 24V8C4 6.89543 4.89543 6 6 6H14V4.5ZM6 8H14V24H6V8Z"
      fillRule="evenodd"
    />
    <path d="M18 8V6H26C27.1046 6 28 6.89543 28 8V24C28 25.1046 27.1046 26 26 26H18V24H26V8H18Z" />
    <path d="M20.225 15.7L22.1 13.2C22.3883 12.8156 23 13.0195 23 13.5V18.5C23 18.9805 22.3883 19.1844 22.1 18.8L20.225 16.3C20.0917 16.1222 20.0917 15.8778 20.225 15.7Z" />
  </BaseIcon>
);
