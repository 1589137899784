import { useQuery } from '@tanstack/react-query';

import { LuzmoDashboard } from '@polyai/common/api/resources/luzmo/types';

import api from 'api';
import { useIsFeatureOn } from 'hooks/useFeatureSwitch';
import {
  PROJECT_RESOURCE,
  useProjectResource,
} from 'hooks/useProjectResources';
import useSampleProject from 'hooks/useSampleProjects/useSampleProject';

type GetIntegrationResponse = {
  id: string;
  embed_dashboards: LuzmoDashboard[];
};

const useAnalytics = () => {
  const { isFeatureOn } = useIsFeatureOn();
  const { isProjectResourceEnabled } = useProjectResource();
  const { inSampleProject } = useSampleProject();

  const isEnabled =
    isFeatureOn('jupiter_analytics') &&
    isProjectResourceEnabled(PROJECT_RESOURCE.ANALYTICS);

  const {
    data: integration,
    isLoading: isIntegrationLoading,
    isSuccess: isIntegrationSuccess,
    error: integrationError,
  } = useQuery<GetIntegrationResponse>(
    ['analytics', 'integration'],
    () => api.getIntegration(),
    {
      enabled: !inSampleProject && isEnabled,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  return {
    integration,
    integrationError,
    isIntegrationLoading,
    isIntegrationSuccess,
  };
};

export default useAnalytics;
