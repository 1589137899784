import ENDPOINTS from 'api/endpoints';
import { doPost } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { generateNextPath } from 'lib/routeHelper';
import { EndResponse, RespondBody, RespondResponse } from './types';

export const startChat = ({
  accountId,
  projectId,
  clientEnv,
  languageCode,
}: {
  accountId: string;
  projectId: string;
  clientEnv: string;
  languageCode: string;
}) => {
  return doPost<RespondResponse>(
    generateNextPath(ENDPOINTS.START_CHAT, { accountId, projectId }),
    LOGGER_TYPE.JUPITER,
    {
      client_env: clientEnv,
      asr_lang_code: languageCode,
      tts_lang_code: languageCode,
    },
    { version: 'v2' },
  );
};

export const respond = ({
  accountId,
  projectId,
  conversationId,
  clientEnv,
  body,
}: {
  accountId: string;
  projectId: string;
  conversationId: string;
  clientEnv: string;
  body: RespondBody;
}) => {
  return doPost<RespondResponse>(
    generateNextPath(ENDPOINTS.RESPOND, {
      accountId,
      projectId,
      conversationId,
    }),
    LOGGER_TYPE.JUPITER,
    { ...body, client_env: clientEnv },
    { version: 'v2' },
  );
};

export const startDraftChat = ({
  accountId,
  projectId,
  languageCode,
}: {
  accountId: string;
  projectId: string;
  languageCode: string;
}) => {
  return doPost<RespondResponse>(
    generateNextPath(ENDPOINTS.DRAFT_START_CHAT, { accountId, projectId }),
    LOGGER_TYPE.JUPITER,
    {
      asr_lang_code: languageCode,
      tts_lang_code: languageCode,
    },
    { version: 'v2' },
  );
};

export const draftRespond = ({
  accountId,
  projectId,
  conversationId,
  body,
}: {
  accountId: string;
  projectId: string;
  conversationId: string;
  body: RespondBody;
}) => {
  return doPost<RespondResponse>(
    generateNextPath(ENDPOINTS.DRAFT_RESPOND, {
      accountId,
      projectId,
      conversationId,
    }),
    LOGGER_TYPE.JUPITER,
    body,
    { version: 'v2' },
  );
};

export const startTemplateChat = ({
  projectId,
  languageCode,
}: {
  projectId: string;
  languageCode: string;
}) => {
  return doPost<RespondResponse>(
    generateNextPath(ENDPOINTS.TEMPLATE_START_CHAT, { projectId }),
    LOGGER_TYPE.JUPITER,
    {
      asr_lang_code: languageCode,
      tts_lang_code: languageCode,
    },
    { version: 'v2' },
  );
};

export const templateRespond = ({
  projectId,
  conversationId,
  body,
}: {
  accountId: string;
  projectId: string;
  conversationId: string;
  clientEnv: string;
  body: RespondBody;
}) => {
  return doPost<RespondResponse>(
    generateNextPath(ENDPOINTS.TEMPLATE_RESPOND, { projectId, conversationId }),
    LOGGER_TYPE.JUPITER,
    body,
    { version: 'v2' },
  );
};

export const endChat = ({
  accountId,
  projectId,
  conversationId,
  clientEnv,
}: {
  accountId: string;
  projectId: string;
  conversationId: string;
  clientEnv: string;
}) => {
  return doPost<EndResponse>(
    generateNextPath(ENDPOINTS.END_CHAT, {
      accountId,
      projectId,
      conversationId,
    }),
    LOGGER_TYPE.JUPITER,
    {
      client_env: clientEnv,
    },
    { version: 'v2' },
  );
};

export const endTemplateChat = ({
  projectId,
  conversationId,
}: {
  accountId: string;
  projectId: string;
  conversationId: string;
  clientEnv: string;
}) => {
  return doPost<EndResponse>(
    generateNextPath(ENDPOINTS.TEMPLATE_END_CHAT, {
      projectId,
      conversationId,
    }),
    LOGGER_TYPE.JUPITER,
    {},
    { version: 'v2' },
  );
};
